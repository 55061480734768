import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './App.css';

const PasswordManager = () => {
    const [passwordData, setPasswordData] = useState({ password: '', word: '' });

    useEffect(() => {
        fetchPassword();
    }, []);

    const fetchPassword = async () => {
        try {
            const response = await axios.get('https://password-api.volynska.tech/api/generate-password/');
            setPasswordData(response.data);
        } catch (error) {
            console.error('There was an error fetching the data!', error);
        }
    };

    const handleGeneratePassword = async () => {
        try {
            const response = await axios.get('https://password-api.volynska.tech/api/generate-password/');
            setPasswordData(response.data);
        } catch (error) {
            console.error('There was an error generating the password!', error);
        }
    };

    const copyText = () => {
        const textToCopy = passwordData.password;
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                showNotification();
            })
            .catch(err => {
                console.error('Помилка копіювання тексту: ', err);
            });
    };

    const showNotification = () => {
        const notification = document.getElementById("copyNotification");
        notification.style.display = "block";
        setTimeout(() => {
            notification.style.display = "none";
        }, 3000);
    };

    return (
        <section className="main_content">
            <p>Слово: <strong>{passwordData.word}</strong></p>
            <p>Пароль: <strong id="textToCopy">{passwordData.password}</strong></p>
            <div>
            <button id="copy_button" onClick={copyText}>Копіювати</button>
            <button type="submit" onClick={handleGeneratePassword}>Генерувати пароль</button>
            </div>
            <div id="copyNotification">Текст скопійовано!</div>
        </section>
    );
};

export default PasswordManager;
