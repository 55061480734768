import React from 'react';
import MenuToggle from './components/MenuToggle';
import PasswordGenerator from "./PasswordGenerator";
import './App.css';

function App() {
    return (

        <div className="App">
            <header className="App-header">
                <h1>Генератор паролів</h1>
                <h4><q>Цей сервіс допоможе створити надійний і легкозапам'ятовуваний пароль на основі українських слів.</q></h4>
                <PasswordGenerator />

                <MenuToggle />
                {/* Інші компоненти */}
            </header>
        </div>

    );
}

export default App;