import React, { useState, useEffect } from 'react';
import './MenuToggle.css';
import $ from 'jquery';


const MenuToggle = () => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (isOpen) {
            $('html, body').css('overflowY', 'hidden');
            const submenuOffset = $('#submenu').offset().top - 20;
            $('html, body').animate({ scrollTop: submenuOffset }, 1000, function() {
                $('html, body').css('overflowY', 'auto');
            });
        } else {
            $('html, body').css('overflowY', 'auto');
        }
    }, [isOpen]);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

     return (
        <div className="button_box">
            <button id="toggleButton" onClick={handleToggle}>Як це працює?🕵️‍♂️</button>
            <div id="submenu" className={`submenu ${isOpen ? 'open' : ''}`}>
                <h3 className="list_topic">Принцип роботи сервісу:</h3>
                <ol>
                    <li>Ви бачите початкове слово на основі якого буде створено пароль. </li>
                    <li>Перетворення символів: 
                        <ul>
                            <li>Перша літера - велика.</li>
                            <li>Літери "а" та "і" замінюються на "@" і "!" відповідно.</li>
                            <li>Додаються 4 випадкові цифри та знак оклику.</li>
                        </ul>
                    </li>
                </ol>

                <p>Таким чином створюється пароль який максимально наближений до стандартів інформаційної безпеки. 
                Щоб скопіювати пароль в буфер обміну натисніть кнопку «Копіювати». 
                Паролі ніде не зберігаються для забезпечення конфіденційності та приватності.
                 </p>
                <br />
                
                <h3 className="list_topic">Приклад:</h3>
                <p>Надійний і запам'ятовуваний пароль можна створити з простого слова за декілька кроків.
                    Ось як за допомогою кількох правил, можна зробити простий і надійний пароль.</p>
                <br />
                <p>Беремо просте слово і перевіряємо за допомогою сервісу перевірки паролів. Бачимо, що пароль супер не надійний.</p>    
                <br />
                <div className="image_1"></div>
                <br />
                <p>Те ж саме слово із застосуванням правил нашого сервісу, і ми бачимо, що пароль став супер надійним.</p>  
                <br />
                <div className="image_2"></div>
                <br />
                
                <h3 className="list_topic">Правила поводження з паролями:</h3>
                <ol>
                <li>Не повідомляйте свій пароль нікому, включаючи колег та IT-персонал.</li>
                <li>Не використовуйте один і той самий пароль для різних сервісів.</li>
                <li>Не зберігайте паролі у відкритому вигляді (на папірцях, у текстових файлах тощо).</li>
                <li>Використовуйте менеджер паролів для безпечного зберігання.</li>
                <li>Негайно змінюйте пароль, якщо виникає підозра, що він був скомпрометований.</li>
                </ol>
                
                <h3 className="list_topic">Вимоги до надійного пароля:</h3>
                <ol>
                <li>Довжина - мінімум 12 символів.</li>
                <li>Комбінація великих і малих літер, цифр та спеціальних символів.</li>
                <li>Відсутність особистої інформації (дати народження, імена тощо).</li>
                <li>Унікальність - не повторюйте попередні паролі.</li>
                </ol>

            </div>
        </div>
    );
};
export default MenuToggle;
